.homePage {
  height: calc(100vh - 40px);
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  .nav-logo {
    width: 90px;
    height: 90px;
    background: url('../../assets/images/circle-img-min.png') no-repeat;
    background-size: 90px 90px;
    margin: 10px auto;
  }
  .nav-title {
    width: 92px;
    height: 20px;
    background: url('../../assets/images/zxfz-min.png') no-repeat;
    background-size: 92px 20px;
    margin: 0 auto;
    margin-bottom: 21px;
  }
  .nav-desc {
    width: 194px;
    height: 25px;
    background: linear-gradient( 135deg, #165DFF 0%, #BD4AFF 100%);
    margin: 0 auto;
    margin-bottom: 100px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .nav-desc-box {
      border-radius: 6px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      font-size: 12px;
      text-align: center;
      color: #666666;
    }
  }
  .footer-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    width: 316px;
    .submit-btn1 {
      width: 316px;
      height: 44px;
      background: linear-gradient( 136deg, #165DFF 0%, #BD4AFF 100%);
      border-radius: 58px 58px 58px 58px;
      text-align: center;
      line-height: 44px;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      font-style: normal;
      text-transform: none;
      margin: 0 auto 10px;
      cursor: pointer;
    }
    .shouquan {
      font-weight: 500;
      font-size: 12px;
      color: #999999;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-bottom: 5px;
    }
    .yonghu {
      font-weight: 500;
      font-size: 12px;
      color: #417AFF;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
  }
}


