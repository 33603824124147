body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.arco-input {
  background: transparent !important;
  border-radius: 2px !important;
  border: 1px solid #dddddd !important;
}


.arco-input:focus {
  border-color: rgb(22, 93, 255) !important;
}



