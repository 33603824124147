.scan-root {
    width: 100%;
    height: 100%;
    background: #333;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;

    .scan-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
        width: 380px;
        height: 536px;
        background: #fff;

        h1 {
            height: 45px;
            font-size: 32px;
            font-weight: 400;
            color: #222;
            line-height: 45px;
            margin: 52px auto 38px;
        }

        .scan-content {
            position: relative;
            height: 280px;
            width: 280px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .scan-mask {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: hsla(0, 0%, 100%, .8);

            &>div:first-child {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: white;
                padding: 5px;
                box-shadow: 0px 0px 5px 0 rgb(21 20 20 / 45%);
                ;
            }
        }

        .scan-mask-success {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: hsla(0, 0%, 100%, .8);
            font-size: 20px;
        }

        &>div:last-child {
            margin-top: 10px;
            width: 242px;
            font-size: 22px;
            font-weight: 400;
            color: #222;
            line-height: 30px;
        }

    }


}

.code-modal {

    .sub-code {
        text-align: right;
    }

    .arco-input-error {
        border-color: red !important;
    }
}
