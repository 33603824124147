.tiktok {
  width: 100%;
  height: 100vh;
  background: #fff;
  box-sizing: border-box;
}
html,
body,
#root,
.App ,.matrixcontainer{
    width: 100%;
    height: 100%;
}
 .matrixcontainer>div:first-child
{
  width: 100%;
  height: 100%;
}